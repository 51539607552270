jQuery(document).ready(function($) {
  // obecne
  var bodyWidth = window.innerWidth;

  // události při resize okna
  $(window).on('resize', function() {
    // osetreni, zda se velikost zmenila
    if (bodyWidth !== window.innerWidth) {
      // nastavíme novou šířku
      bodyWidth = window.innerWidth;
      // zresetuj menu
      resetMenu();
    }
  });

  // události při načtení stránky
  $(window).on('load', function() {});

  // události při scroolování
  $(window).on('scroll', function() {
    // rolovací menu
    rollMenu();
  });

  // mobilní menu
  function switchMenu() {
    // označíme zda je menu zavřeno či nikoliv
    if ($('.nav__switcher').hasClass('is-open')) {
      $('.nav__switcher').removeClass('is-open');
    } else {
      $('.nav__switcher').addClass('is-open');
    }

    // otevřeme produktové menu
    if ($('.nav').hasClass('is-open')) {
      $('.nav').removeClass('is-open');
    } else {
      $('.nav').addClass('is-open');
    }
  }
  // při změně rozlišení resetujeme menu
  function resetMenu() {
    $('.nav__switcher, .nav').removeClass('is-open');
  }
  // spouštěč
  $('.nav__switcher').on('click', function() {
    switchMenu();
  });

  // rolovací menu
  function rollMenu() {
    if ($(this).scrollTop() > 1) {
      $('.header').addClass('header--fixed');
    } else {
      $('.header').removeClass('header--fixed');
    }
  }

  $('.js-scroll').click(function(e) {
    console.log('click');
    e.preventDefault();
    var aid = $(this).attr('href');
    $('html,body').animate({ scrollTop: $(aid).offset().top }, 'slow');
    return false;
  });

  // výběr jazyka v header
  $('.header__language__choosen').click(function(e) {
    $('.header__language').toggleClass('is-open');
    e.preventDefault();
    e.stopPropagation();
  });
  $('body:not(.header__language__chooser)').click(function(e) {
    $('.header__language').removeClass('is-open');
  });

  // testimonial boxy
  $('.testimonial__menu ul li').on('click', function() {
    var tab_id = $(this).attr('data-tab');

    $('.testimonial__menu ul li').removeClass('is-active');
    $('.testimonial__item').removeClass('is-active');
    $(this).addClass('is-active');
    $('#' + tab_id).addClass('is-active');
  });

  if (window.outerWidth <= 768) {
    $('.menu-item-has-children').on('click', function(e) {
      $(this)
        .find('ul')
        .toggle();
    });

    $('.menu-item-has-children > a').on('click', function(e) {
      e.preventDefault();
    });
  }

  // faq drowpdown
  jQuery('.faq__item__question').on('click', function() {
    jQuery(this)
      .parent()
      .find('.faq__item__answer')
      .slideToggle(300);

    jQuery(this).toggleClass('faq__item__question--is-open');
  });

  // footer mobile drowpdown
  jQuery('.footer__nav h2').on('click', function() {
    jQuery(this)
      .parent()
      .find('.footer__hide-on-mobile')
      .slideToggle(100);

    jQuery(this).toggleClass('is-open');
  });
});
